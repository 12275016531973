import { Injectable } from '@angular/core'

import { AlertParam } from '../domain/alert-param.model'
import { Alert } from '../domain/alert.model'
import { AlertsFacadeBase } from './alerts-facade-base'

@Injectable()
export class OnlyLogAlertsStrategy extends AlertsFacadeBase {
  override addSuccess(alert: AlertParam): void {
    console.log(alert)
  }
  override addError(alert: AlertParam): void {
    console.error(alert)
  }
  override addInfo(alert: AlertParam): void {
    console.log(alert)
  }
  override addWarning(alert: AlertParam): void {
    console.warn(alert)
  }

  override remove(id: Alert['id']): void {
    throw new Error('Method not implemented.')
  }
}
